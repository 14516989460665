<template>
  <div class="padding-10 lc-main">
    <h4>下单流程</h4>
    <h4>Demo Process</h4>
    <br>
    <img src="../../assets/image/liucheng.jpg"  style="width:95%;margin:0px auto">
  </div>
</template>
<script>
export default {
  name:'xiadanliucheng'
}
</script>
<style lang="less" scoped>
.lc-main{
  text-align: center;
  padding: 30px 0px;
}
</style>

 